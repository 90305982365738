import React, { useState} from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import * as BS from "react-bootstrap"
// import { FirebaseContext } from '../Firebase';
// import './style.scss'


const Newsletter = () => {
  // const { firebase } = useContext(FirebaseContext);
  const [email, setEmail] = useState('john@doe.com')
  const [sent] = useState(false)
  const [message] = useState('')
 
  const changeEmailHandler = (event) => {
    setEmail(event.target.value)
  }

  const addToDB = () => {
    // firebase.db.collection('emails').add({
    //   email: email,
    //   date: new Date(),
    // })
    console.log('it worked!!')
  }

  // useEffect(() => {
  //   localStorage.setItem('email', JSON.stringify(email))
  // }, [email])

  const handleSubmit = e => {
    e.preventDefault();
    // setMessage('Thank you for joining! Please check your inbox for your #1 spreadsheet')
    addToMailchimp(email) // listFields are optional if you are only capturing the email address.
    .then(data => {console.log(data)})
    .catch(() => {})
    // setEmail('')
    // setSent(true)
    // var elem = document.querySelector('form');
    // elem.parentNode.removeChild(elem);
    addToDB()
    window.location.href = '/thank-you/'
  }



    return (
      <BS.Container >
      <div className="News">
        <div className="letter">
          <h2>{message}</h2>
  
          {sent ?
          <div></div> :
          <div className="form">
            <form className="subscribe" onSubmit={handleSubmit}>
            <BS.Form.Group className='formit'>
            <BS.Form.Row mx-auto>
              <BS.Col sm={8}>
                <BS.Form.Control size="lg" value={email} onChange={changeEmailHandler} name="EMAIL" id="mce-EMAIL"
                type="text" className="subscribe-email" placeholder="" onFocus={e => setEmail('')}/>
              </BS.Col> 
              <BS.Col sm={4} className='but'>
                <BS.Button type="submit" className="btn-lg">Join Now</BS.Button>
              </BS.Col>
            </BS.Form.Row>
            </BS.Form.Group>
            </form>
          </div>}


        </div>
      </div>
      </BS.Container>
    )
}

export default Newsletter