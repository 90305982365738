import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import Newsletter from '../components/Newsletter/Newsletter'
import Why from '../components/featured/Why'
import Pricing from '../components/featured/Pricing'
import * as BS from "react-bootstrap"
import "bootstrap/dist/css/bootstrap-grid.css"


const Signup = props => {

  const doThis = () => {
    window.scrollTo(0, 0)
  }


const renderIcons = (edge, index) => {

  if(edge.node.businessRef.icon !== 'nf' && edge.node.businessRef.icon !== 'none'){

    return (
     
      <tr className="table-striped tbody tr:nth-of-type(odd)">
        <td><img style={{maxWidth: 20, marginBottom: 0}} src={`/images/business-icons/${edge.node.businessRef.icon}`} alt={`${edge.node.businessRef.icon} name`}></img> {edge.node.businessRef.name}</td>
        <td>{edge.node.currency}{new Intl.NumberFormat().format(edge.node.amount)}</td>
        <td className="d-none d-sm-table-cell">{edge.node.businessRef.what[0].toUpperCase() + edge.node.businessRef.what.substring(1)}</td>
        <td className="d-none d-sm-table-cell">{edge.node.businessRef.founders[0]}</td>
        <td className="d-none d-sm-table-cell">{edge.node.investors[0]}</td>
        <td className="d-none d-sm-table-cell">{edge.node.date.split('T')[0].split('-').reverse().join('-')}</td>
        <td className="d-none d-sm-table-cell">{edge.node.businessRef.locations[0].country}</td>
    </tr>
    )
  }
}

  return (
    <section>
      <Helmet>
        <html lang="en" />
        <title>Sign Up To UnicornAlert</title>
        <meta
          name="title"
          content="Sign Up To UnicornAlert"
        ></meta>
        <meta
          name="description"
          content="Get the latest updates from startups, investors and fundraising in your realtime UnicornAlert dashboard."
        />
      </Helmet>
    
      <BS.Container style={{ paddingLeft: 0, paddingRight: 0, maxWidth: 1400}}>

      <BS.Row style={{display: 'block', textAlign: 'center', marginTop: 70, marginBottom: 0}}>

      <h1 style={{color: '#19191b', fontSize: 60, fontWeight: 700, marginTop: 0, fontFamily: '"CircularStd", sans-serif', paddingLeft: 10, paddingRight: 10}} id='line1'>These StartUps Are On Fire <span role="img" aria-label="fire">🔥</span></h1>
      <h1 style={{color: '#19191b', fontSize: 60, fontWeight: 700, marginTop: 0, fontFamily: '"CircularStd", sans-serif', paddingLeft: 10, paddingRight: 10}}>Get One Spreadsheet, Once A Week!</h1>
      <h2 style={{color: '#34383b', fontWeight: 300, marginBottom: 25, paddingLeft: 10, paddingRight: 10}}>Who raised what? - amounts, terms, founders and investors</h2>


      <Newsletter/>


      <p className="strapline">Don't miss the next Unicorn!</p>
      <img className='logos' variant="top" src={`/images/theunicorns.png`} alt='unicorns'></img>

      <BS.Col>
      <BS.Table justify-content-center striped bordered hover size="sm" style={{maxWidth: 1100, margin: '0px auto', marginBottom: -19, fontSize: 14 }}>
      <thead>
      <tr>
      <th>Company</th>
      <th>Funding (USD)</th>
      <th className="d-none d-sm-table-cell">Market</th>
      <th className="d-none d-sm-table-cell">Founder</th>
      <th className="d-none d-sm-table-cell">Investor</th>
      <th className="d-none d-sm-table-cell">Funding Date</th>
      <th className="d-none d-sm-table-cell">Location</th>
      </tr>
      </thead>

      <tbody>
      {props.data.allFunding.edges.slice(0,8).map(renderIcons)}

      </tbody>
      </BS.Table>
      <hr className="hrr"></hr>

      </BS.Col>
      </BS.Row>
      <BS.Container style={{maxWidth: 1000, marginBottom: 100}}>
      <h2 class="display-4" style={{textAlign: 'center', marginBottom: 100, fontWeight: 500, fontSize: 55}}>3 simple steps</h2>
      <BS.Row id='r1'>
        <BS.Col style={{minWidth: 250}}>
        <img src="/unicorn-email.png" alt="Unicorn Alert Email" id='p1'></img>
        </BS.Col>
        <BS.Col>
          <p>Step 1</p>
          <h3>Head To Your Inbox Every Monday Morning</h3>
          <p>You will recieve a weekly email with the link to your detailed spreadsheet of the latest startup investments.</p>
          
          <BS.Button type="submit" className="btn btn-lg" id="toTop" onMouseDown={doThis}>Sign Up It's Free</BS.Button>
          
        </BS.Col>
      </BS.Row>
      <BS.Row id='r2'>
      <BS.Col style={{minWidth: 250}}l>
        <img src="/unicornalert-spreadsheet.png" alt="Unicorn Alert Email"  id='p2'></img>
        </BS.Col>
        <BS.Col>
        <p>Step 2</p>
          <h3>Get To Know The Fastest Growing StartUps Of The Week</h3>
          <p> View a <a target='_blank' rel="noreferrer" href="https://docs.google.com/spreadsheets/d/1VIDThQYdCQDeqypNuCuWpzC4XxKhDJS5whHi2kpfQI0/" style={{color: '#007bff'}}>previous example</a></p>
          <BS.Row style={{marginTop: 30, marginBottom: 30}}>
          <BS.Col sm={6} id='c2'>
          <li>Business name</li>
          <li>Website</li>
          <li>Market</li>
          <li>Amount</li>
          <li>Funding date</li>
          </BS.Col>
          <BS.Col sm={6} id='c2'>
          <li>Founders</li>
          <li>Investors</li>
          <li>Location</li>
          <li>Linkedin</li>
          <li>Twitter</li>

      </BS.Col>
          
      </BS.Row>
        
      <a href='#top'><BS.Button type="submit" className="btn btn-lg" onMouseDown={doThis}>Join Now</BS.Button></a>
        </BS.Col >
      
      </BS.Row>
      <BS.Row id='r3'>

        <BS.Col style={{minWidth: 250}}>

        <img src="/unicornalert-build.png" alt="Unicorn Alert Spreadsheet" id='p3'></img>
        </BS.Col>
        <BS.Col>
          <p>Step 3</p>
          <h3>Sell, Create & Invest With Your Free Data </h3>
          <BS.Row style={{marginTop: 30, marginBottom: 30}} id='c3'>
            <ul>
          <li>Create investment signals</li>
          <li>Get leads for your sales team</li>
          <li>Explore market research</li>
          <li>Generate insights</li>
          <li>Build reports and rankings</li>
          <li>Data enrichment</li>
          
          <li>Or just be inspired!</li>
          </ul>

          </BS.Row>
          <a href='#top'><BS.Button type="submit" className="btn btn-lg" onMouseDown={doThis}>Send Me My Spreadsheet</BS.Button></a>
        </BS.Col>
      </BS.Row>
      </BS.Container>

    



      <BS.Container style={{maxWidth: 800, marginTop: 130, marginBottom: 130}}>
      <BS.Col>
      <h2 class="display-4" style={{textAlign: 'center', marginBottom: 40, fontWeight: 500, fontSize: 55}}>How can this all be free?</h2>
      <p class="lead" style={{textAlign: 'center', margin: 'auto', marginBottom: 20}}>This weekly <b>spreadsheet is free and will stay free!</b></p>

      <p class="lead" style={{textAlign: 'center', margin: 'auto', marginBottom: 20}}>We hope that you will find that we bring value and that some of you will sign up for our daily updates, dashboard and premium signals.</p>
      <p class="lead" style={{textAlign: 'center', margin: 'auto', marginBottom: 20}}>We can't wait to see how you're going to use this data every week. Please remember to show us when you do!</p>


      </BS.Col>
      </BS.Container>
      <Why/>
      <Pricing/>
        
      </BS.Container>
    
</section>
   
  )
}

export const query = graphql`
  {
    allFunding(sort: { order: DESC, fields: date }, limit: 28) {
      edges {
        node {
          amount
          date
          investors
          currency
          id
          businessRef {
            ... on Business {
              id
              name
              website
              what
              icon
              founders
              locations {
                city
                country
              }
            }
          }
        }
      }
      totalCount
    }
    allInvestor(filter: {count: {gt: 0}} sort: { order: DESC, fields: count }, limit: 10) {
      edges {
        node {
          name
          count
        }
      }
      totalCount
    }
    allBusiness(sort: { order: DESC, fields: totalRaised }, limit: 10) {
      edges {
        node {
          totalRaised
          name
          currency
          icon
        }
      }
      totalCount
    }
    allCategory(sort: { order: DESC, fields: amount }, limit: 6) {
      edges {
        node {
          amount
          howMany
          name
          url
        }
      }
    }
    allLocation(filter: {amount: {gt: 0}} sort: { order: DESC, fields: amount }, limit: 10) {
      edges {
        node {
          amount
          city
          country
          currency
          howMany
        }
      }
    }
    allStats(limit: 10, sort: { order: DESC, fields: date }) {
      edges {
        node {
          amountCount
          date
          dateFormat
          investmentCount
        }
      }
    }
  }
`
export default Signup
