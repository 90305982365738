
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Why = () => {

  return (
<section>
<h2 class="display-4" style={{textAlign: 'center', marginBottom: 20, fontWeight: 500, fontSize: 55}}>Why did all they sign up?</h2>
<p class="lead" style={{maxWidth: 800, textAlign: 'center', margin: 'auto', marginBottom: 40}}>Discover a few of the reasons given to us for signing up to UnicornAlert</p>
<div class="card-columns">
<div class="card p-3 text-right">
    <blockquote class="blockquote mb-0 card-body">
      <p>I just don’t have time to read Techcrunch. This spreadsheet gives me the numbers of everything that has happened during the week. </p>
      <footer class="blockquote-footer">
        <small class="text-muted">
          Alan H. <cite title="Source Title">Project Manager</cite>
        </small>
      </footer>
    </blockquote>
  </div>
  <div class="card p-3 text-right">
    <blockquote class="blockquote mb-0 card-body">
      <p>Every week I have to make a report about tech innovation for my team. This is the first slide to my presentation.</p>
      <footer class="blockquote-footer">
        <small class="text-muted">
          Account Exec at <cite title="Source Title">PR Firm</cite>
        </small>
      </footer>
    </blockquote>
  </div>
  <div class="card p-3 text-right">
    <blockquote class="blockquote mb-0 card-body">
      <p>I don’t care about quotes from founders or definitions of industry jargon. I just want the data so I can get on with my research.</p>
      <footer class="blockquote-footer">
        <small class="text-muted">
          Garbiel S. <cite title="Source Title">Analyst</cite>
        </small>
      </footer>
    </blockquote>
  </div>
  <div class="card p-3 text-right">
    <blockquote class="blockquote mb-0 card-body">
      <p>I'm only interested in startups in my market: biotech and healthtech. So I added a data filter to the spreadsheet. That’s it, done!</p>
      <footer class="blockquote-footer">
        <small class="text-muted">
          Megan R.  <cite title="Source Title">CSO</cite>
        </small>
      </footer>
    </blockquote>
  </div>
  <div class="card p-3 text-right">
    <blockquote class="blockquote mb-0 card-body">
      <p>I have a business in construction and I want to know who is raising funds in construction and that’s it, nothing else! I can narrow this down.</p>
      <footer class="blockquote-footer">
        <small class="text-muted">
          Alex W. <cite title="Source Title">CEO</cite>
        </small>
      </footer>
    </blockquote>
  </div>
  <div class="card p-3 text-right">
    <blockquote class="blockquote mb-0 card-body">
      <p>Seed investment in startups generally happens 18 months before the next rounds. I can group the seed investments together and predict series A, B and beyond.</p>
      <footer class="blockquote-footer">
        <small class="text-muted">
          Jeffrey M. <cite title="Source Title">Angel Investor</cite>
        </small>
      </footer>
    </blockquote>
  </div>

  <div class="card p-3 text-right">
    <blockquote class="blockquote mb-0 card-body">
      <p>By tracking early stage investments, I can discover new trends. It’s like a crystal ball.</p>
      <footer class="blockquote-footer">
        <small class="text-muted">
          Aïcha B. <cite title="Source Title">Account Manager</cite>
        </small>
      </footer>
    </blockquote>
  </div>
  <div class="card p-3 text-right">
    <blockquote class="blockquote mb-0 card-body">
      <p>I trade stocks, startup fundraising gives me signals where the next big thing will come from.</p>
      <footer class="blockquote-footer">
        <small class="text-muted">
          Kaitkyn C. <cite title="Source Title">Trader</cite>
        </small>
      </footer>
    </blockquote>
  </div>
  <div class="card p-3 text-right">
    <blockquote class="blockquote mb-0 card-body">
      <p>I sell trade ideas to my clients. I can use each large investment as illustrations of rapid growth.</p>
      <footer class="blockquote-footer">
        <small class="text-muted">
          Joe R. <cite title="Source Title">Broker</cite>
        </small>
      </footer>
    </blockquote>
  </div>

  <div class="card p-3 text-right">
    <blockquote class="blockquote mb-0 card-body">
      <p>The info has been a great talking point for my client dinners.</p>
      <footer class="blockquote-footer">
        <small class="text-muted">
          Philip R. <cite title="Source Title">Sales Exec</cite>
        </small>
      </footer>
    </blockquote>
  </div>
  <div class="card p-3 text-right">
    <blockquote class="blockquote mb-0 card-body">
      <p>I love launching the Spanish version of XYZ and it’s not always easy to find what’s going on in other countries when the data comes in an article format.</p>
      <footer class="blockquote-footer">
        <small class="text-muted">
          Aurora D. <cite title="Source Title">Entrepreneur</cite>
        </small>
      </footer>
    </blockquote>
  </div>
  <div class="card p-3 text-right">
    <blockquote class="blockquote mb-0 card-body">
      <p>Japan gives me a lot of inspiration but I don’t speak japanese. The spreadsheet helps me focus on the most exciting asian startups.</p>
      <footer class="blockquote-footer">
        <small class="text-muted">
          Chris M. <cite title="Source Title">Entrepreneur</cite>
        </small>
      </footer>
    </blockquote>
  </div>

  <div class="card p-3 text-right">
    <blockquote class="blockquote mb-0 card-body">
      <p>It’s free clean data about businesses that have received a large cash influx. It’s a no brainer. I just forward it to my sales team and they start calling!</p>
      <footer class="blockquote-footer">
        <small class="text-muted">
          Stephen L. <cite title="Source Title">Sales Manager</cite>
        </small>
      </footer>
    </blockquote>
  </div>
  <div class="card p-3 text-right">
    <blockquote class="blockquote mb-0 card-body">
      <p>I run sales at a HR software company. The startups in this list are recruiting like crazy, this is basically the names of all my future clients.</p>
      <footer class="blockquote-footer">
        <small class="text-muted">
          Jenna H. <cite title="Source Title">Sales Director</cite>
        </small>
      </footer>
    </blockquote>
  </div>
  <div class="card p-3 text-right">
    <blockquote class="blockquote mb-0 card-body">
      <p>I have a family to provide for and I don’t want to join a company that is fragile. This list helps me weed out the best opportunities.</p>
      <footer class="blockquote-footer">
        <small class="text-muted">
          Simon R. <cite title="Source Title">Programmer</cite>
        </small>
      </footer>
    </blockquote>
  </div>
  <div class="card p-3 text-right">
    <blockquote class="blockquote mb-0 card-body">
      <p>I get the most satisfaction in small teams. I want to join a business at the beginning of the adventure. </p>
      <footer class="blockquote-footer">
        <small class="text-muted">
          Nadine V. <cite title="Source Title">Project Manager</cite>
        </small>
      </footer>
    </blockquote>
  </div>
  <div class="card p-3 text-right">
    <blockquote class="blockquote mb-0 card-body">
      <p>I target businesses that are not great at promoting themselves and I then offer my digital marketing services.</p>
      <footer class="blockquote-footer">
        <small class="text-muted">
          Steph G. <cite title="Source Title">Digital Marketer</cite>
        </small>
      </footer>
    </blockquote>
  </div>
  <div class="card p-3 text-right">
    <blockquote class="blockquote mb-0 card-body">
      <p>I'd love to start working in tech next year. These are the most exciting businesses to work for!</p>
      <footer class="blockquote-footer">
        <small class="text-muted">
          Chen A. <cite title="Source Title">Student</cite>
        </small>
      </footer>
    </blockquote>
  </div>
</div>
</section>
  )

}

export default Why