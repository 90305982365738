
import React from 'react';
import * as BS from "react-bootstrap";
import { Link } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';

const Pricing = () => {

  // const doThis = () => { 
  //   window.scrollTo(0, 0)
  // }

 const sendMail = () => {
    var link = "mailto:hello@unicornalert.io"
            //  + "?cc=myCCaddress@example.com"
            //  + "&subject=" + encodeURIComponent("This is my subject")
            //  + "&body=" + encodeURIComponent(document.getElementById('myText').value)
    ;
    
    window.location.href = link;
}

  return (
    <BS.Container>
    <section>
    <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
    <h2 class="display-4" style={{textAlign: 'center', marginBottom: 30, fontWeight: 500 , fontSize: 55}}>Pricing</h2>
      <p class="lead" style={{maxWidth: 800, textAlign: 'center', margin: 'auto', marginBottom: 20}}>If you're looking for weekly updates, it's totally free! If you need more, we've tried to create a pricing that will suit everybody. And please don't hesitate to contact us if you need something special.</p>
    </div>
    <div class="card-deck mb-3 text-center">
        <div class="card mb-4 box-shadow">
          <div class="card-header">
            <h4 class="my-0 font-weight-normal">Free</h4>
          </div>
          <div class="card-body d-flex flex-column">
            <h1 class="card-title pricing-card-title">$0 <small class="text-muted">/ mo</small></h1>
            <ul class="list-unstyled mt-3 mb-4">
              <li>Weekly Spreadsheet:</li>
              <li>Business Name / Website</li>
              <li>Market / Amount</li>
              <li>Funding Date / Founders</li>
              <li>Investors / Location</li>
              <li>Linkedin / Twitter</li>
            </ul>
            <Link to={`/signup/`} style={{marginTop: 54}}><button type="button" class="btn btn-lg btn-block btn-outline-primary mt-auto">Sign up for free</button></Link>
          </div>
        </div>
        {/* <div class="card mb-4 box-shadow">
          <div class="card-header">
            <h4 class="my-0 font-weight-normal">Pro</h4>
          </div>
          <div class="card-body d-flex flex-column">
            <h1 class="card-title pricing-card-title">$9 <small class="text-muted">/ mo</small></h1>
            <ul class="list-unstyled mt-3 mb-4">
              <li>Everything From Free Pack +</li>
              <li>Daily Spreadsheet</li>
              <li>Filter By Market</li>
              <li>Filter By Location</li>
              <li>Business Logos + Icons</li>
              <li>Team Urls</li>
              <li>Founder Linkedin</li>
              <li>Founder Twitter</li>
            </ul>
            <Checkout/>
     
          </div>
        </div> */}
        <div class="card mb-4 box-shadow">
          <div class="card-header">
            <h4 class="my-0 font-weight-normal">Enterprise</h4>
          </div>
          <div class="card-body d-flex flex-column">
            <h1 class="card-title pricing-card-title">TBD <small class="text-muted">/ mo</small> </h1>
            <ul class="list-unstyled mt-3 mb-4">
              <li>Live API</li>
              <li>Bespoke Dashboard</li>
              <li>Advanced Signals</li>
              <li>CRM Integration</li>
            </ul>
            <button type="button" class="btn btn-lg btn-block btn-primary mt-auto" onMouseDown={sendMail}>Contact us</button>
          </div>
        </div>
      </div>


    </section>
  </BS.Container>
  )
}

export default Pricing